<script setup lang="ts" generic="T">
import type { Props, Instance } from 'tippy.js'
import type { UiPopup } from '#build/components'
import type { MenuItem, UiMenuListType } from '~/types'

const props = defineProps<{
  items: MenuItem<T>[]
  trigger?: Props['trigger']
  selectedItem?: MenuItem<T>
  isPending?: boolean
  sub?: boolean
  placement?: Props['placement']
  onTriggerKeyEvent?: (e: KeyboardEvent) => boolean
}>()
const emit = defineEmits<{
  (evt: 'trigger-keydown', item: KeyboardEvent): void
  (evt: 'toggle-shown', item: boolean): void
  (evt: 'done'): void
}>()

const popup = ref<InstanceType<typeof UiPopup>>()
const menuList = ref<UiMenuListType>()
const isOpen = ref(false)

const triggerEvents = computed(() => props.sub ? 'click' : props.trigger)

function onStateChange({ isShown }: Instance['state']) {
  isOpen.value = isShown
  emit('toggle-shown', isShown)
}

function onTriggerKeyDown(event: KeyboardEvent) {
  const cancel = props.onTriggerKeyEvent?.(event) ?? false
  if (!props.sub && !cancel) {
    menuList.value?.onKeyDown(event)
  }
  emit('trigger-keydown', event)
}

function show() {
  popup.value?.show()
}

function hide() {
  popup.value?.close()
  focusTrigger()
}

function done() {
  hide()
  emit('done')
}

function focusTrigger() {
  popup.value?.focusButton()
}

function focus() {
  menuList.value?.focus()
}

function blur() {
  menuList.value?.blur()
}

defineExpose({
  isOpen: () => popup.value?.isOpen,
  show,
  hide,
  focusTrigger,
  focus,
  blur,
})
</script>

<template>
  <UiPopup
    ref="popup"
    :trigger="triggerEvents"
    :placement="placement ?? sub ? 'right' : undefined"
    :no-focus-trigger-on-hide="sub"
    :sub="sub"
    @trigger-keydown="onTriggerKeyDown"
    @state="onStateChange">
    <template #trigger-title="{ state }">
      <slot name="trigger-title" :state="state">
        <span class="icon-more" />
      </slot>
    </template>
    <UiMenuList
      ref="menuList"
      :items="items"
      :selected-item="selectedItem"
      :is-pending="isPending"
      :is-sub="sub"
      @close-request="hide"
      @done="done">
      <template #item="{ item, active }">
        <slot name="item" :item="item" :active="active" />
      </template>
      <template #item-content="{ item, active }">
        <slot name="item-content" :item="item" :active="active" />
      </template>
    </UiMenuList>
  </UiPopup>
</template>
