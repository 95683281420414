import {
  filterSaturate,
  formatCss,
  formatRgb,
  interpolate,
  modeLrgb,
  modeOklch,
  modeP3,
  modeRec2020,
  modeRgb,
  toGamut,
  useMode,
  wcagContrast
} from 'culori/fn'

useMode(modeOklch)
useMode(modeRgb)
useMode(modeP3)
useMode(modeRec2020)
useMode(modeLrgb)

const toColorGamut = {
  rgb: toGamut('rgb'),
  p3: toGamut('p3')
}

export function contrast(oklch1, oklch2) {
  const displayGamut = toGamut(getSupportedColorGamut())
  const c1 = displayGamut(prepareColor(oklch1))
  const c2 = displayGamut(prepareColor(oklch2))
  return wcagContrast(c1, c2)
}

export function displayableOklch(oklch, displayGamut = 'rgb') {
  const color = prepareColor(oklch)
  if (!color) {
    return ''
  }
  let c = toColorGamut[displayGamut](color)
  c = {
    ...c,
    ...(c.r ? { r: Math.fround(c.r) } : {}),
    ...(c.g ? { g: Math.fround(c.g) } : {}),
    ...(c.b ? { b: Math.fround(c.b) } : {})
  }
  return displayGamut === 'rgb' ? formatRgb(c) : formatCss(c)
}

export function interpolateOklch(stops) {
  return interpolate(stops, 'oklch', { h: { fixup: arr => arr } })
}

export function maxContrastColor(oklch) {
  if (!oklch) {
    return ''
  }
  oklch = prepareColor(oklch)
  const whiteContrast = wcagContrast(oklch, {
    mode: 'oklch',
    l: 1,
    c: 0,
    h: 0
  })
  const blackContrast = wcagContrast(oklch, {
    mode: 'oklch',
    l: 0,
    c: 0,
    h: 0
  })
  return whiteContrast > blackContrast ? 'white' : 'black'
}

export function saturateMix(
  oklch1,
  oklch2,
  mixPosition = 0.5,
  saturation = 0.5
) {
  const toOklch = toGamut('oklch', 'rgb')
  const inter = interpolate(
    [
      toColorGamut.rgb(prepareColor(oklch1)),
      toColorGamut.rgb(prepareColor(oklch2))
    ],
    'rgb',
    { h: { fixup: arr => arr } }
  )
  const mixedRgb = inter(mixPosition)
  const filter = filterSaturate(saturation, 'rgb')
  const { l, c, h } = toOklch(filter(mixedRgb))
  return [l, c, h]
}

export function getSupportedColorGamut() {
  if (typeof window !== 'undefined') {
    if (window.matchMedia('(color-gamut: rec2020)').matches) {
      return 'rec2020'
    }
    if (window.matchMedia('(color-gamut: p3)').matches) {
      return 'p3'
    }
  }
  return 'rgb'
}

function prepareColor(oklch) {
  if (!oklch) {
    return ''
  }
  if (Array.isArray(oklch)) {
    const [l, c, h] = oklch
    oklch = { mode: 'oklch', l, c, h }
  }
  return oklch
}
