import type { mastodon } from '#types'
import { contrast, displayableOklch, maxContrastColor, saturateMix } from '~/lib/color/culori'

type ThemeConfig = Required<Omit<mastodon.v1.AccountTheme, 'name'>>
type ThemeCssVar = Record<string, string>

export const defaultThemeConfig: ThemeConfig = {
  colorOklch: [0.93, 0.0079, 92],
  font: 'sans',
  gridGap: 'normal',
  cornerRadius: 'big'
}

const themes = new WeakMap<mastodon.v1.AccountTheme, Record<string, string>>()

export function useTheme(themeConfig?: Ref<mastodon.v1.AccountTheme | undefined> | undefined) {
  const defaultTheme = buildTheme()
  const theme = ref<ReturnType<typeof buildTheme>>(defaultTheme)
  if (themeConfig) {
    watch(themeConfig, (themeConfig) => {
      if (themeConfig) {
        if (!themes.has(themeConfig)) {
          themes.set(themeConfig, buildTheme(themeConfig))
        }
        theme.value = themes.get(themeConfig)!
      } else {
        theme.value = defaultTheme
      }
    }, { immediate: true })
  }
  return theme
}

function buildTheme(themeConfig?: mastodon.v1.AccountTheme): Record<string, string> {
  if (typeof themeConfig?.colorOklch === 'string') {
    themeConfig.colorOklch = (themeConfig.colorOklch as string).split(',').map(v => parseFloat(v.trim()))
  }
  const { font, gridGap, cornerRadius, colorOklch } = {
    ...defaultThemeConfig,
    ...themeConfig
  }

  const [mL, mC, mH] = colorOklch

  let panelColor = [1, 0, 0]
  if (contrast([1, 0, 0], colorOklch) < 1.1) {
    panelColor = [
      mL * 0.95,
      mC * 0.2,
      (mH + 30) % 360
    ]
  }

  const textColorCssString = maxContrastColor(colorOklch)
  const accentColor = [
    textColorCssString === 'white' ? 0.95 : 0.1,
    mC < 0.25 ? mC + 0.05 : mC,
    (mH + 180) % 360
  ]

  const minMix = 0.3
  const maxMix = 0.7
  const mixPos = (maxMix - minMix) * mL + minMix
  const minSat = 0.15
  const maxSat = 0.4
  const maxMc = 0.37
  const saturation = (maxSat - minSat) / maxMc * (maxMc - mC) + minSat
  const [l, c, h] = saturateMix(panelColor, colorOklch, mixPos, saturation)
  const panelColorMinor = [l, c, (h + 0) % 360]

  return {
    '--color-bg-rgb': displayableOklch(colorOklch),
    '--color-bg-p3': displayableOklch(colorOklch, 'p3'),
    '--color-text': textColorCssString,
    '--color-accent-rgb': displayableOklch(accentColor),
    '--color-accent-p3': displayableOklch(accentColor, 'p3'),
    '--color-panel-rgb': displayableOklch(panelColor),
    '--color-panel-p3': displayableOklch(panelColor, 'p3'),
    '--color-panel-minor': displayableOklch(panelColorMinor),
    '--color-panel-text': maxContrastColor(panelColor),
    '--corner-radius': `var(--theme-corner-radius-${cornerRadius})`,
    '--grid-gap': `var(--theme-grid-gap-${gridGap})`,
    '--font-family-main': `var(--theme-font-family-${font})`
  }
}

export function buildTheme2(value?: mastodon.v1.AccountTheme) {
  const theme = { ...defaultThemeConfig, ...value }
  const mainColor = ref(fixIfColorString(theme?.colorOklch))
  const font = ref(theme?.font)
  const gridGap = ref(theme?.gridGap)
  const cornerRadius = ref(theme?.cornerRadius)

  function setMainColor(oklch: ThemeConfig['colorOklch']) {
    mainColor.value = fixIfColorString(oklch)
  }
  function fixIfColorString(oklch: ThemeConfig['colorOklch'] | string) {
    if (typeof oklch === 'string') { oklch = (oklch as string).split(', ').map(v => parseFloat(v)) }
    return oklch
  }
  const textColorCssString = computed(() => maxContrastColor(mainColor.value))
  const textColorPanelCssString = computed(() => maxContrastColor(panelColor.value))

  const accentColor = computed(() => {
    if (!mainColor.value) { return '' }
    const [, mC, mH] = mainColor.value
    const l = textColorCssString.value === 'white' ? 0.95 : 0.1
    const c = mC < 0.25 ? mC + 0.05 : mC
    const h = (mH + 180) % 360
    return [l, c, h]
  })

  const panelColor = computed(() => {
    if (!mainColor.value) { return '' }
    const [l, c, h] = mainColor.value
    return contrast([1, 0, 0], mainColor.value) < 1.1
      ? [l * 0.95, c * 0.2, (h + 30) % 360]
      : [1, 0, 0]
  })

  const panelColorMinor = computed(() => {
    if (!mainColor.value) { return '' }
    const [mL, mC] = mainColor.value
    const minMix = 0.3
    const maxMix = 0.7
    const mixPos = (maxMix - minMix) * mL + minMix
    const minSat = 0.15
    const maxSat = 0.4
    const maxMc = 0.37
    const saturation = (maxSat - minSat) / maxMc * (maxMc - mC) + minSat
    const [l, c, h] = saturateMix(panelColor.value, mainColor.value, mixPos, saturation)
    return [l, c, (h + 0) % 360]
  })

  const themeCssVars = computed<ThemeCssVar>(() => {
    return {
      '--color-bg-rgb': displayableOklch(mainColor.value) as string,
      '--color-bg-p3': displayableOklch(mainColor.value, 'p3') as string,
      '--color-text': textColorCssString.value,
      '--color-accent-rgb': displayableOklch(accentColor.value) as string,
      '--color-accent-p3': displayableOklch(accentColor.value, 'p3') as string,
      '--color-panel-rgb': displayableOklch(panelColor.value) as string,
      '--color-panel-p3': displayableOklch(panelColor.value, 'p3') as string,
      '--color-panel-minor': displayableOklch(panelColorMinor.value) as string,
      '--color-panel-text': textColorPanelCssString.value,
      ...cornerRadiusCssVar.value,
      ...gridGapCssVar.value,
      ...fontCssVar.value
    }
  })

  function setFont(f: ThemeConfig['font']) { font.value = f }
  const fontCssVar = computed<ThemeCssVar>(() => ({ '--font-family-main': `var(--theme-font-family-${font.value})` }))

  function setGridGap(g: ThemeConfig['gridGap']) { gridGap.value = g }
  const gridGapCssVar = computed<ThemeCssVar>(() => ({ '--grid-gap': `var(--theme-grid-gap-${gridGap.value})` }))

  function setCornerRadius(v: ThemeConfig['cornerRadius']) { cornerRadius.value = v }
  const cornerRadiusCssVar = computed<ThemeCssVar>(() => ({ '--corner-radius': `var(--theme-corner-radius-${cornerRadius.value})` }))

  function setTheme(themeConfig?: mastodon.v1.AccountTheme) {
    if (themeConfig) {
      const config = { ...defaultThemeConfig, ...themeConfig }
      setMainColor(config.colorOklch)
      setFont(config.font)
      setGridGap(config.gridGap)
      setCornerRadius(config.cornerRadius)
    }
  }

  function applyThemeVarToElement(el: HTMLElement, cssVar: ThemeCssVar) {
    const [key, val] = Object.entries(cssVar)[0]
    el.style.setProperty(key, val)
  }

  function applyThemeToElement(el: HTMLElement) {
    applyThemeVarToElement(el, fontCssVar.value)
    applyThemeVarToElement(el, gridGapCssVar.value)
    applyThemeVarToElement(el, cornerRadiusCssVar.value)

    const style = el?.style
    if (style) {
      Object.entries(themeCssVars.value).forEach(([prop, value]) => {
        style.setProperty(prop, value)
      })
    }
  }

  return {
    themeCssVars,
    setTheme,
    setMainColor,
    setFont,
    setGridGap,
    setCornerRadius,
    applyThemeToElement
  }
}
