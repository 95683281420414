<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type { Props } from 'tippy.js'

const props = withDefaults(defineProps<{
  placement?: Props['placement']
  arrow?: Props['arrow']
  trigger?: Props['trigger']
  theme?: Props['theme']
  hideOnClick?: Props['hideOnClick']
  noFocusTriggerOnHide?: boolean
  sub?: boolean
}>(),
{
  placement: 'bottom-start',
  arrow: false,
  trigger: 'click',
  theme: 'indi',
  hideOnClick: true
})

const emit = defineEmits(['state', 'trigger-keydown', 'trigger-focus', 'on-hide'])

const tippy = ref<typeof Tippy>()
const triggerButton = ref<HTMLElement>()
const durationMs = ref(100)

function appendTo () {
  return props.sub ? (triggerEl.value?.closest('[data-tippy-root]') ?? undefined) : document?.body
}

function onHide () {
  emit('on-hide')
  if (props.noFocusTriggerOnHide) { return }
  focusButton()
}

function focusButton () {
  triggerButton.value?.focus()
}

const isOpen = ref(false)

const show = computed(() => () => {
  isOpen.value = true
  tippy.value?.show()
})
const close = computed(() => () => {
  isOpen.value = false
  tippy.value?.hide()
})

const triggerEl = computed(() => triggerButton.value)

defineExpose({
  show,
  close,
  isOpen,
  focusButton,
  triggerEl
})
</script>

<template>
  <Tippy
    ref="tippy"
    class="tippy-popup"
    interactive
    allow-html
    :theme="props.theme"
    :arrow="props.arrow"
    :hide-on-click="props.hideOnClick"
    :append-to="appendTo"
    :offset="[0, 8]"
    :duration="durationMs"
    :trigger="props.trigger"
    max-width="none"
    :placement="props.placement"
    :on-hide="onHide"
    @state="emit('state', $event)">
    <template #default="{ state }">
      <button
        ref="triggerButton"
        class="popup-trigger compact"
        :class="{ active: state.isVisible }"
        @keydown="emit('trigger-keydown', $event)"
        @focus="emit('trigger-focus')">
        <slot name="trigger-title" :state="state">
          Popup
        </slot>
      </button>
    </template>
    <template #content="{ state, hide }">
      <UiCollapsibleBox
        v-if="state.isVisible"
        class="theme-panel"
        :open="state.isVisible"
        :t="`${durationMs}ms`">
        <slot :close="hide" />
      </UiCollapsibleBox>
    </template>
  </Tippy>
</template>
