<script setup lang="ts" generic="T">
import type { MenuItem } from '~/types'

const props = defineProps<{
  item: MenuItem<T>
}>()
const iconClass = computed(() => props.item.icon?.startsWith('icon-') ? props.item.icon : `icon-${props.item.icon}`)
</script>

<template>
  <span v-if="item.icon" :class="iconClass" />
  <span>
    <span>{{ item.label }}</span>
    <div v-if="item.desc" class="desc">{{ item.desc }}</div>
  </span>
  <span v-if="item.checked" class="icon-check small" />
  <span v-if="item.items?.length" class="icon-chevron-right small" />
</template>
