<script setup lang="ts" generic="T">
import type { NuxtLink } from '#build/components'
import type { MenuItem, UiPopupMenuType } from '~/types'

const props = defineProps<{ item: MenuItem<T> }>()

const emit = defineEmits<{
  (evt: 'keydown', item: KeyboardEvent): void
  (evt: 'submenu-toggled', item: boolean): void
  (evt: 'done' | 'mouseenter' | 'mouseleave' | 'select'): void
}>()

const button = ref<HTMLElement>()
const link = ref<InstanceType<typeof NuxtLink>>()
const submenu = ref<UiPopupMenuType>()

const type = computed(() => {
  if (props.item.items?.length) {
    return 'submenu'
  }
  if (props.item.to) {
    return 'link'
  }
  return 'button'
})

const el = computed<HTMLElement>(() => {
  if (button.value) {
    return button.value
  }
  if (link.value) {
    return link.value.$el
  }
  return undefined
})

defineExpose({
  focus: () => {
    if (el?.value) {
      el.value.focus()
    }
    else if (submenu.value) {
      submenu.value.focusTrigger()
    }
  },
  blur: () => {
    if (el?.value) {
      el.value.blur()
    }
    else if (submenu.value) {
      submenu.value.blur()
    }
  },
  showSubmenu: () => {
    submenu.value?.show()
  },
  isOpenSubmenu: () => submenu.value?.isOpen(),
  hideSubmenu: () => {
    submenu.value?.hide()
    submenu.value?.focusTrigger()
  },
  focusSubmenu: () => {
    submenu.value?.focus()
  },
})
</script>

<template>
  <button
    v-if="type === 'button'"
    ref="button"
    :class="{ section: item.separator }"
    :disabled="!item.command"
    @keydown="emit('keydown', $event)"
    @click="emit('select')">
    <slot>
      <UiMenuItemContent :item="item" />
    </slot>
  </button>
  <NuxtLink
    v-else-if="type === 'link'"
    ref="link"
    :to="item.to"
    @keydown="emit('keydown', $event)">
    <slot>
      <UiMenuItemContent :item="item" />
    </slot>
  </NuxtLink>
  <UiPopupMenu
    v-else-if="type === 'submenu'"
    ref="submenu"
    sub
    :items="item.items ?? []"
    @done="emit('done')"
    @trigger-keydown="emit('keydown', $event)"
    @toggle-shown="emit('submenu-toggled', $event)">
    <template #trigger-title>
      <slot>
        <UiMenuItemContent :item="item" />
      </slot>
    </template>
  </UiPopupMenu>
</template>
