<script setup lang="ts" generic="T">
import type { MenuItem, UiMenuItemType } from '~/types'

type ListItem = MenuItem<T> & {
  active: boolean
  select: () => Promise<boolean>
  focus: () => void
  blur: () => void
  isOpenSubmenu: () => boolean
  showSubmenu: () => void
  hideSubmenu: () => void
  focusSubmenu: () => void
}

const props = defineProps<{
  items: MenuItem<T>[]
}>()

const emit = defineEmits<{
  (evt: 'trigger-keydown', item: KeyboardEvent): void
  (evt: 'submenu-toggled', item: boolean): void
  (evt: 'close-request' | 'done'): void
}>()

const itemComponents = ref<UiMenuItemType[]>([])
const activeItem = computed(() => listItems.value.find(({ active }) => active))
const listItems = ref<ListItem[]>([])

watch(() => props.items, (items) => {
  listItems.value = items.map<ListItem>((item, index) => {
    function component() {
      return index < itemComponents.value.length ? itemComponents.value[index] : undefined
    }
    return {
      active: item.checked ?? false,
      select: async () => {
        if (item.command) {
          await Promise.resolve(item.command(item))
          emit('done')
          return true
        }
        return false
      },
      focus: () => {
        component()?.focus()
      },
      blur: () => {
        component()?.blur()
      },
      isOpenSubmenu: () => component()?.isOpenSubmenu() ?? false,
      hideSubmenu: () => {
        component()?.hideSubmenu()
      },
      showSubmenu: () => {
        component()?.showSubmenu()
      },
      focusSubmenu: () => {
        component()?.focusSubmenu()
      },
      ...item,
    }
  })
}, { immediate: true })

function setIndex(index: number, open: boolean) {
  listItems.value = listItems.value.map((item, i) => ({
    ...item,
    active: i === index,
  }))
  const openItem = listItems.value.find(item => item.isOpenSubmenu())
  if (openItem && !openItem.active) {
    openItem.hideSubmenu()
  }
  activeItem.value?.focus()
  if (open) {
    openSubmenu()
  }
}

function shiftIndex(delta: number, open: boolean) {
  const length = listItems.value.length
  if (!activeItem.value && delta < 0) {
    setIndex(length - 1, open)
  }
  else {
    const index = listItems.value.findIndex(({ active }) => active)
    setIndex((index + delta % length + length) % length, open)
  }
}

function openSubmenu() {
  if (activeItem.value?.items?.length) {
    activeItem.value.showSubmenu()
    nextTick(() => {
      activeItem.value?.focusSubmenu()
    })
  }
}

function breakEvent(event: KeyboardEvent) {
  event.preventDefault()
  event.stopPropagation()
}

async function onKeyDown(event: KeyboardEvent) {
  if (props.items.length === 0) {
    return false
  }
  switch (event.key) {
    case 'ArrowUp':
      breakEvent(event)
      shiftIndex(-1, false)
      return true
    case 'ArrowDown':
      breakEvent(event)
      shiftIndex(1, false)
      return true
    case 'ArrowRight':
      breakEvent(event)
      openSubmenu()
      return true
    case 'ArrowLeft':
      breakEvent(event)
      emit('close-request')
      return true
    case 'Enter':
      if (await activeItem.value?.select()) {
        breakEvent(event)
      }
      return true
    case 'Escape':
      breakEvent(event)
      event.stopImmediatePropagation()
      emit('done')
      return true
    default:
      return false
  }
}

defineExpose({
  focus: () => {
    if (!activeItem.value) {
      setIndex(0, true)
    }
    activeItem.value?.focus()
  },
  blur: () => {
    if (activeItem.value) {
      activeItem.value?.blur()
      setIndex(-1, true)
    }
  },
  onKeyDown,
})
</script>

<template>
  <ul class="menu-list" role="menu">
    <UiScrollIntoViewEl
      v-for="(item, i) in listItems"
      :key="i"
      as="li"
      role="menuitem"
      :active="item.active"
      :class="{ active: item.active, section: item.separator }"
      @mouseenter="setIndex(i, true)"
      @click="item.select()">
      <slot name="item" :item="item" :active="item.active">
        <span class="menu-item-wrap">
          <UiMenuItem
            ref="itemComponents"
            :item="item"
            @select="item.select()"
            @done="emit('done')"
            @keydown="onKeyDown"
            @submenu-toggled="emit('submenu-toggled', $event)">
            <slot name="item-content" :item="item" :active="item.active" />
          </UiMenuItem>
        </span>
      </slot>
    </UiScrollIntoViewEl>
  </ul>
</template>

<style lang="scss">
ul[role="menu"] {
  list-style: none;

  &:focus {
    outline: none;
  }
}

li[role="menuitem"] {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--padding-small);
  min-height: var(--button-height);
  cursor: pointer;

  &.section {
    border-block-start: 1px solid var(--color-line);
  }

  .menu-item-wrap {

    button,
    a {
      background-color: transparent;
      padding-inline: var(--padding-base);
      padding-block: var(--padding-small);
      font-size: inherit;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: start;
      gap: var(--padding-small);
      width: 100%;
      height: auto;

      &:active,
      &:hover,
      &:focus,
      &.focused {
        &::after {
          content: none
        }

      }

      &:has([class^="icon-"]:first-child),
      &:has([class^="px-img"]:first-child) {
        padding-inline-start: var(--padding-small);
      }

      &:has([class^="icon-"]:last-child),
      &:has([class^="px-img"]:last-child) {
        padding-inline-end: var(--padding-small);
      }
    }
  }

  a {
    text-decoration: none;
  }

  &:hover {
    background-color: var(--color-accent-extra-light);
  }

  &.active {
    background-color: var(--color-accent-light);
    color: white;

    button,
    a {
      color: white;
    }
  }
}
</style>
